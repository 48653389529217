.my-base {
  border-bottom-width: 0.5px;
  border-color: white;
  padding: 5px;
}

.my-expiry {
  border-bottom-width: 0.5px;
  border-color: white;
  padding: 5px;
  width: 90px;
}

.expiry-invalid {
  border-bottom-width: 0.5px;
  border-color: white;
  padding: 5px;
  width: 90px;
}

.my-expiry-complete {
  border: none;
  width: 65px;
}

.my-complete {
  border: none;
}

.my-invalid {
  border-color: red;
}

.AtomApp input.ajs-input.js-empty {
  font-size: 9px !important;
}
